@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Work+Sans&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 1 solid transparent;
  font-family: "Work Sans", sans-serif;
}

html,
body {
  -webkit-text-size-adjust: 100%;
  color: var(--foreground);
  background: var(--background);
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  text-rendering: optimizeSpeed;
}
